.mg-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    padding: 10px;
    box-sizing: border-box;
    z-index: 999;

}

.mg-modal.open {
    visibility: visible;
    opacity: 1;
}

.modal-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 500px;
    z-index: 999;
}

@media (max-width: 480px) {
    .modal-container {
        width: 95%;
        padding: 15px;
    }
}
